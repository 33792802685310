import axios from 'axios'

let BASE_URL = 'https://api.myunicorn.site/api'

// let BASE_URL = 'http://localhost:3001/api'

export async function getUser(id) {
    let request = await axios.get(`${BASE_URL}/users/${id}`)

    return request.data
}

export async function getUserGreetings(id) {
    let request = await axios.get(`${BASE_URL}/users/alerts/${id}`)

    return request.data
}
export async function greet(body) {
    let request = await axios.post(`${BASE_URL}/users/alerts/accept`, body)

    return request.data
}

export async function getEasyUser(id) {
    let request = await axios.get(`${BASE_URL}/users/easy/${id}`)

    return request.data
}

export async function getUserGuides(id) {
    let request = await axios.get(`${BASE_URL}/users/guides/${id}`)

    return request.data
}

export async function launch(body) {
    let request = await axios.post(`${BASE_URL}/users/launch`, body)

    return request.data
}

export async function tapEgg(id) {
    let request = await axios.post(`${BASE_URL}/users/tap/${id}`)

    return request.data
}

export async function collectCoins(id) {
    let request = await axios.post(`${BASE_URL}/users/collect/${id}`)

    return request.data
}

export async function startFarm(id) {
    let request = await axios.post(`${BASE_URL}/users/farm/${id}`)

    return request.data
}

export async function feedUnicorn(body) {
    let request = await axios.post(`${BASE_URL}/users/feed`, body)

    return request.data
}

export async function getCustomer(id) {
    let request = await axios.get(`${BASE_URL}/shop/customer/${id}`)

    return request.data
}

export async function buyItem(body) {
    let request = await axios.post(`${BASE_URL}/shop/buy`, body)

    return request.data
}

export async function getFriends(id) {
    let request = await axios.get(`${BASE_URL}/friends/${id}`)

    return request.data
}

export async function collectRoyalty(id) {
    let request = await axios.post(`${BASE_URL}/friends/collect/${id}`)

    return request.data
}


export async function verify(id) {
    let request = await axios.post(`${BASE_URL}/users/verify/${id}`)

    return request.data
}

export async function getTasks(id) {
    let request = await axios.get(`${BASE_URL}/tasks/${id}`)

    return request.data
}

export async function accomplishTask(body) {
    let request = await axios.post(`${BASE_URL}/tasks/accomplish`, body)

    return request.data
}

export async function startTask(body) {
    let request = await axios.post(`${BASE_URL}/tasks/attach`, body)

    return request.data
}

export async function claimTask(body) {
    let request = await axios.post(`${BASE_URL}/tasks/claim`, body)

    return request.data
}

export async function getHatching(id) {
    let request = await axios.get(`${BASE_URL}/users/hatching/${id}`)

    return request.data
}