import css from './GamePage.module.css'
import { useEffect, useRef, useState } from 'react';
import unicorn from '../assets/unicorn1.png'
import shade from '../assets/shade.png'
import egg1 from '../assets/egg1.png'
import egg2 from '../assets/egg2.png'
import egg3 from '../assets/egg3.png'
import {Link, useSearchParams} from 'react-router-dom'
import { getEasyUser, getHatching, getUserGreetings, getUserGuides, greet, tapEgg } from '../api';
// import SweetImage from '../assets/sweet.svg'
// import SourImage from '../assets/sour.svg'
// import EnergyImage from '../assets/energy.svg'
// import shieldImage from '../assets/shield.svg'
import Home from '../assets/home.svg'
import Shop from '../assets/shop.svg'
import Tasks from '../assets/tasks.svg'
import Friends from '../assets/friends.svg'
import Other from '../assets/otherIcon.svg'
import healthImg from '../assets/health.svg'
import ms from 'ms';
// import back from '../assets/back.svg'
// import ShieldIcon from '../Icons/Shield';
import unicornHatched from '../assets/unicornHatched.svg'
import eggGrowing from '../assets/eggGrowing.svg'
import completed from '../assets/completed.svg'
import EggStage from './EggStage';
import UnicornStage from './UnicornStage';

const tg = window.Telegram.WebApp

const tgFake = {
  initDataUnsafe: {
    user: {
      id: 791601316
    }
  }
}

export default function GamePage() {

	useEffect(() => {
		document.body.classList = ''
    	document.body.classList.add('background-main');
	}, [])

	let [isLoading, setIsLoading] = useState(true)
	let [hatching, setHatching] = useState(0)

	useEffect(() => {
		getHatching(tg.initDataUnsafe.user.id).then((res) => {
			setHatching(res.hatching)
			setIsLoading(false)
		})

		tg.expand()
	}, [])

    return isLoading == true ? 
	<div className={css.loaderCanvas}>
		<div class={css.loader}></div>
	</div> : (hatching < 7 ? <EggStage></EggStage> : <UnicornStage></UnicornStage>)
}