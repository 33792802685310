// import logo from './logo.svg';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import ErrorBoundary from './ErrorBoundary';

function App() {

  return (
    <div className="App">
      <ErrorBoundary>
        <RouterProvider router={router}></RouterProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
