import css from './FriendsPage.module.css'
import { useEffect, useRef, useState } from 'react';
import {Link} from 'react-router-dom'
import Home from '../assets/home.svg'
import Shop from '../assets/shop.svg'
import Tasks from '../assets/tasks.svg'
import Friends from '../assets/friends.svg'
import Other from '../assets/otherIcon.svg'
import backImage from '../assets/cloudBack.png'
import { collectRoyalty, getFriends, getUserGreetings, greet } from '../api';
const tg = window.Telegram.WebApp

const tgFake = {
	initDataUnsafe: {
	  user: {
		id: 320563506
	  }
	}
  }

export default function FriendsPage() {
	useEffect(() => {
		document.body.classList = ''
  		document.body.classList.add('background-side');
	}, [])

	let [friends, setFriends] = useState([]) 
	let [allRoyalty, setAllRoyalty] = useState(0)
	let [toCollect, setToCollect] = useState(0)

    useEffect(() => {
		getFriends(tg.initDataUnsafe.user.id).then(res => {
			setFriends(res.friends)
			setToCollect(res.toCollect)

			let allRoy = 0

			res.friends.forEach(friend => {
				allRoy += friend.royalty
			})

			setAllRoyalty(allRoy)
		})
      	tg.ready()
    }, [])

	function copy() {
		let link = `https://t.me/unicornspacegame_bot?start=${tg.initDataUnsafe.user.id}`

		navigator.clipboard.writeText(link);

		alert("You successfully copied your refferal link!");
	}

	function handleCollect() {
		collectRoyalty(tg.initDataUnsafe.user.id).then(res => {
			setToCollect(0)
		})
	}

    return (
        <div className={css.gameCanvas}>
			<img src={backImage} id={css.backImage} alt="" />
            <div className={css.textBlock}>
              <h1>Your UniFrens</h1>
			  <p><span>{friends.length}</span> Unifriends</p>
			  <h2>Total bonus: {parseInt(allRoyalty)} $MUCC</h2>
            
			  <div className={css.collectBlock}>
				<button onClick={() => {handleCollect()}}>Collect {toCollect} Royalty</button>
			</div>
			</div>
		
            <div className={css.friendsBlock}>
              {friends.sort((a, b) => b.royalty - a.royalty).map(friend => {
				return (<div className={css.friend}>
					<div className={css.description}>
					  <h2>{friend.username}</h2>
					  <p>Invited {friend.invited}</p>
					</div>
					<h3><span>Royalty:</span> {parseInt(friend.royalty)} $MUCC</h3>
				  </div>)
			  })}
            </div>

			<div className={css.inviteBlock}>
				<button onClick={() => {copy()}}>Invite Friend</button>
			</div>


            <div className={css.navBlock}>
                <Link to={'/'} id={css.sideLeft}><img src={Home} />Home</Link>
                <Link to={'/shop'}><img src={Shop} />UniShop</Link>
                <Link to={'/tasks'}><img src={Tasks} />Tasks</Link>
                <Link to={'/friends'}><img id={css.unifriends} src={Friends} />UniFrens</Link>
				<Link to={'/navigation'} id={css.sideRight}><img src={Other}/>Other</Link>
            </div>
      </div>
    )
}