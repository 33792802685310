import css from './ShopPage.module.css'
import { useEffect, useRef, useState } from 'react';
import {Link} from 'react-router-dom'
import SweetImage from '../assets/sweet.png'
import SourImage from '../assets/sour.png'
import Home from '../assets/home.svg'
import Shop from '../assets/shop.svg'
import Tasks from '../assets/tasks.svg'
import Friends from '../assets/friends.svg'
import Other from '../assets/otherIcon.svg'
import pizzaBack from '../assets/pizzaBack.png'
import shieldImage from '../assets/shield.svg'
import energyImage from '../assets/energy.svg'

import { accomplishTask, buyItem, getCustomer, getUserGreetings, greet } from '../api';
const tg = window.Telegram.WebApp

const tgFake = {
    initDataUnsafe: {
      user: {
        id: 791601316
      }
    }
  }

export default function ShopPage() {

	useEffect(() => {
		document.body.classList = ''
  		document.body.classList.add('background-side');
	}, [])

    let [coins, setCoins] = useState(0)
    let [sweetFood, setSweet] = useState(0)
    let [sourFood, setSour] = useState(0)
	  let [hatching, setHatching] = useState(0)
    let [triggers, setTriggers] = useState([])

    useEffect(() => {
        getCustomer(tg.initDataUnsafe.user.id).then(res => {
            setCoins(res.coins)
            setSweet(res.sweetFood)
            setSour(res.sourFood)
            setHatching(res.hatching)
            setTriggers(res.triggers)
            
            tg.ready()
        })
        return 
    }, [])

    function handleBuy(item) {
      buyItem({
        item,
        quantity: 1,
        telegramId: tg.initDataUnsafe.user.id
      }).then((res) => {
        setCoins(res.coins)

        if(item == 'sourFood') setSour(parseInt(sourFood) + 1)
        if(item == 'sweetFood') setSweet(parseInt(sweetFood)+1)
		    if(item == 'skipEgg') window.location.reload()
      })

      if(item == 'sourFood' && triggers.some(e => e.name == 'buy_sour')) {
        accomplishTask({
          userId: tg.initDataUnsafe.user.id,
          tbId: triggers.find(e => e.name == 'buy_sour').tbId
        })
      }

      if(item == 'sweetFood' && triggers.some(e => e.name == 'buy_sweet')) {
        accomplishTask({
          userId: tg.initDataUnsafe.user.id,
          tbId: triggers.find(e => e.name == 'buy_sweet').tbId
        })
      }
    }

    return (
        <div className={css.gameCanvas}>
            <img src={pizzaBack} id={css.backImage} alt="" />
            <div className={css.textBlock}>
              <h1>Shop</h1>
              <p>You have <span>{coins.toFixed(4)} M$UCC</span></p>
            </div>

			{hatching < 7 ? 
            <div className={css.shopBlock}>
              <div className={css.item}>
                <div className={css.description}>
                  <h2>{'Skip egg stage'} <img src={SweetImage} alt="" /></h2>
                  <p>2000 M$UCC</p>
                </div>
                <button disabled={coins < 2000} onClick={() => {handleBuy('skipEgg')}}>Buy</button>
              </div>
            </div> : 
            <div className={css.shopBlock}>
              <div className={css.item}>
                <div className={css.description}>
                  <h2>{'Sweet food'} <img src={SweetImage} alt="" /></h2>
                  <p>25 M$UCC - You have {sweetFood}</p>
                </div>
                <button disabled={coins < 25} onClick={() => {handleBuy('sweetFood')}}>Buy</button>
              </div>
              <div className={css.item}>
                <div className={css.description}>
                  <h2>{'Sour food'} <img src={SourImage} alt="" /></h2>
                  <p>10 M$UCC - You have {sourFood}</p>
                </div>
                <button disabled={coins < 10} onClick={() => {handleBuy('sourFood')}}>Buy</button>
              </div>
            </div>}

            <div className={css.navBlock}>
                <Link to={'/'} id={css.sideLeft}><img src={Home} />Home</Link>
                <Link to={'/shop'}><img src={Shop} />UniShop</Link>
                <Link to={'/tasks'}><img src={Tasks} />Tasks</Link>
                <Link to={'/friends'}><img id={css.unifriends} src={Friends} />UniFrens</Link>
                <Link to={'/navigation'} id={css.sideRight}><img src={Other}/>Other</Link>
            </div>
      </div>
    )
}