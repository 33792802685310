import css from './GamePage.module.css'
import { useEffect, useRef, useState } from 'react';
import unicorn from '../assets/unicorn1.png'
import shade from '../assets/shade.png'
import egg1 from '../assets/egg1.png'
import egg2 from '../assets/egg2.png'
import egg3 from '../assets/egg3.png'
import {Link, useSearchParams} from 'react-router-dom'
import { getEasyUser, getUserGreetings, getUserGuides, greet, tapEgg } from '../api';
import Home from '../assets/home.svg'
import Shop from '../assets/shop.svg'
import Tasks from '../assets/tasks.svg'
import Friends from '../assets/friends.svg'
import Other from '../assets/otherIcon.svg'
import healthImg from '../assets/health.svg'
import ms from 'ms';
import unicornHatched from '../assets/unicornHatched.svg'
import eggGrowing from '../assets/eggGrowing.svg'
import completed from '../assets/completed.svg'

const tg = window.Telegram.WebApp

const tgFake = {
  initDataUnsafe: {
    user: {
      id: 791601316
    }
  }
}

export default function EggStage() {

	useEffect(() => {
		document.body.classList = ''
    	document.body.classList.add('background-main');
	}, [])

	let [lastTap, setLastTap] = useState('')
    let [coins, setCoins] = useState(0)
	let [taps, setTap] = useState(0)
	let [nextTapIn, setNextTap] = useState('')
    let [unicornAge, setAge] = useState('Egg')
    let [health, setHealth] = useState(0)

    let [shake, setShake] = useState(false)

	let [eggStatus, setEggStatus] = useState(0)

    useEffect(() => {
      if(Object.keys(tg.initDataUnsafe).length !== 0) {
        getEasyUser(tg.initDataUnsafe.user.id).then(res => {
			
		  	setEggStatus(res.hatching)
          	setCoins(res.coins)
			setLastTap(res.lastTap)
          	setHealth(res.health)
			setTap(res.taps)

          tg.ready()
		})
      }
    }, [])

    async function shakeEgg(e) {
		if(!e.target.classList.contains(css.shake)) {
			setShake(true)

			setTimeout(() => {
			  setShake(false)
			}, 1000)

		}
    }


	async function handlePet() {
		tapEgg(tg.initDataUnsafe.user.id).then((res) => {
			setLastTap(res.lastTap)
			setTap(res.taps)
			setHealth(100)
		})
	}

	function formatCountdown(futureTimestamp) {
		const now = Date.now();
		const ms = futureTimestamp - now;
	
		if (ms <= 0) {
			return "00:00:00";
		}
	
		const totalSeconds = Math.floor(ms / 1000);
		const hours = Math.floor(totalSeconds / 3600);
		const minutes = Math.floor((totalSeconds % 3600) / 60);
		const seconds = totalSeconds % 60;
	
		return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
	}
	
	useEffect(() => {
		let interval = setInterval(() => {
			setNextTap(formatCountdown(parseInt(lastTap) + ms('3h')))
		}, 1000);
	
		return () => clearInterval(interval); 
	}, [lastTap]);

    return (
        <div className={css.gameCanvas}>
            <div className={css.topBlock}>
              <div className={css.coinsBlock}>
                <h2>M$UCC</h2>
                <h1>{parseFloat(coins).toFixed(4)}</h1>
              </div>

              <div className={css.levelBlock}>
                <div className={css.dayBlock}>
					<img src={eggGrowing} alt="" />
					<p>Day 1</p>
				</div>
                <progress value={eggStatus/7}></progress>
				<div className={css.dayBlock}>
					<img src={unicornHatched} alt="" />
                	<p>Day 7</p>
				</div>
              </div>
              <span>{unicornAge}</span>
            </div>

			<div className={css.health}>
				<img src={healthImg} alt="" />
				<progress value={health/100}></progress>
			</div>

			<div className={css.unicornBlock}>
				<div className={css.backUni}></div>
				<img src={eggStatus < 3 ? egg1 : (eggStatus > 2 && eggStatus < 5 ? egg2 : (eggStatus > 4 ? egg3 : unicorn))} id={css.unicorn} className={shake && eggStatus < 7 ? css.shake : ''} onClick={(e) => shakeEgg(e)} alt="" />
				<img src={shade} id={css.shade}  alt="" />
			</div>

			<div className={css.foodBlock}>
              <div className={css.buttons}>
                <div className={css.bBlock}>
                	<button disabled={taps == 1 ? true : Date.now() - lastTap < ms('3h')} onClick={() => {handlePet()}}><img src={taps == 1 ? completed : healthImg} /></button>
                </div>
              </div>
			  {taps == 0  && Date.now() - lastTap < ms('3h') ? <p>Please, wait until next health top up: <br></br>{nextTapIn}</p> : ''}
			  {taps == 0 && Date.now() - lastTap > ms('3h') ? <p>Please, top up your egg’s health (1 of 1)</p> : ''}
			  {taps == 1 || taps > 1 ? <p>Your egg is all good for today, please come back tomorrow </p> : ''}
            </div>


            <div className={css.navBlock}>
                <Link to={'/'} id={css.sideLeft}><img src={Home} />Home</Link>
                <Link to={'/shop'}><img src={Shop} />UniShop</Link>
                <Link to={'/tasks'}><img src={Tasks} />Tasks</Link>
                <Link to={'/friends'}><img id={css.unifriends} src={Friends} />UniFrens</Link>
				<Link to={'/navigation'} id={css.sideRight}><img src={Other}/>Other</Link>
            </div>

      </div>
    )
}