import css from './GuidePage.module.css'
import { useEffect, useState } from 'react';
import {Link} from 'react-router-dom'
import Science from '../assets/science.png'
import Cloud from '../assets/cloudHint.png'
import Trophy from '../assets/trophyHint.png'
const tg = window.Telegram.WebApp

const tgFake = {
    initDataUnsafe: {
      user: {
        id: 791601316
      }
    }
  }

export default function GuidePage() {
	document.body.classList = ''
  	document.body.classList.add('background-side');


    useEffect(() => {
        tg.ready()
        return 
    }, [])

    return (
        <div className={css.main}>
           	<h2>Here’s the deal</h2>

			   <div className={css.hints}>
				<div className={css.hint} id={css.first}>
					<div className={css.left}>
						<img src={Science} alt="" />
					</div>
					<div className={css.right}>
						<h1>Health bar</h1>
						<p>Keep your unicorn healthy by <span>feeding</span> it on time. A healthy unicorn gives you more coins. <br></br><br></br>Don’t let the bar go below 1% otherwise you will lose your Level and your Unicorn!</p>
					</div>
				</div>
				<div className={css.hint} id={css.second}>
					<div className={css.left}>
						<img src={Cloud} alt="" />
					</div>
					<div className={css.right}>
						<h1>Unicorn Ideas</h1>
						<p>A <span>healthy</span> unicorn can generate creative ideas. Sometimes it even might be project insights that never appear anywhere else. Use this for your own good and watch the <span>health bar!</span></p>
					</div>
				</div>
				<div className={css.hint} id={css.third}>
					<div className={css.left}>
						<img src={Trophy} alt="" />
					</div>
					<div className={css.right}>
						<h1>Farming & Gains</h1>
						<p>Your <span>healthy and fed unicorn farms Mini UCC (Unicorn Coins)</span> over time, which you can claim to purchase items, upgrades, and/or convert all the mini coins into <span>$UCC</span>!</p>
					</div>
				</div>
			</div>
      	
			<Link to={'/final'}>Next 1/2</Link>
		</div>
    )
}