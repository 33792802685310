import { useEffect } from 'react'
import css from './MarketPlacePage.module.css'
import { Link } from 'react-router-dom'
import nfts from '../assets/nftsBig.png'
import back from '../assets/back.svg'
const tg = window.Telegram.WebApp

const tgFake = {
	initDataUnsafe: {
	  user: {
		id: 791601316
	  }
	}
  }

  
function openLink(link) {
    tg.openLink(link)
}

export default function MarketPlacePage() {

	useEffect(() => {
		document.body.classList = ''
  		document.body.classList.add('background-market');
	}, [])
    
    useEffect(() => {
        tg.ready()
    }, [])

    return (
        <div className={css.wrapper}>
            <div className={css.header}>
                <Link to={'/navigation'}><img src={back} alt="" /></Link>
                <div className={css.right}>   
                <h1>UniTrade</h1>
                <div className={css.doubleText}>
                    <h2>MINTING SOON</h2>
                </div>
                </div>
            </div>

            <div className={css.main}>
                <p>TAVO Investment-supported company focusing on NFT APY% based, based on MFT BTC, and ETH investments. <br></br><br></br>
                A unified NFT APY% based system where Unicorn Space provides yearly rebuy of the bought by users NFTs with a starting of 30% APY based on NFT level and up to 70% APY rebuy rate.</p>
            </div>

            <div className={css.buttons}>
                {/* <Link to={'https://ucorn.space/nft/'} id={css.learnMore}>Learn more</Link> */}
                <button onClick={() => {openLink('https://t.me/mexman_manager')}} id={css.request}>Request Early Access</button>
            </div>

            <div className={css.nfts}>
                <img src={nfts} alt="" />
            </div>
      </div>
    )
}