import { useEffect, useRef, useState } from 'react'
import css from './NavigationPage.module.css'
import { Link, useSearchParams } from 'react-router-dom'
import nfts from '../assets/unicornsNFT.png'
import x from '../assets/x.svg'
import unicornIcon from '../assets/unicornIcon.svg'
import cryptoIcon from '../assets/crypto.png'
import back from '../assets/back.svg'
import { getUserGuides, launch } from '../api'
const tg = window.Telegram.WebApp

const tgFake = {
	initDataUnsafe: {
	  user: {
		id: 791601316
	  }
	}
  }

function openLink(link) {
    tg.openLink(link)
}

export default function NavigationPage() {


	useEffect(() => {
		document.body.classList = ''
  		document.body.classList.add('background-landing');
	}, [])
    
    // const hasFetched = useRef(false);

    // useEffect(() => {
    //     if (!hasFetched.current) {
    //         hasFetched.current = true;

    //         getUserGuides(tg.initDataUnsafe.user.id).then((res) => {
    //             // alert(res.start)
    //             if(res.start == false) window.location.href = '/ecosystem'
    //             // alert(res.game)
    //         });

    //         
    //     }
    // }, []);

    return (
        <div className={css.wrapper}>
            <div className={css.header}>
                <Link to={'/'}><img src={back} alt="" /></Link>
                <div className={css.right}>
                    <h1>UNICORN</h1>
                    <div className={css.doubleText}>
                        <h1>SPACE</h1>
                        <span>MULTI-APP</span>
                    </div>
                </div>
            </div>
            <div className={css.welcome}>
                <p>Welcome to our amazing Multi-app...</p>
            </div>

            <div className={css.buttons}>
                <div className={css.topRow}>
                    <Link to={'/'}>
                        <div className={css.risingButton}>
                            <p>UNICORN RISING GAME</p>
                        </div>
                    </Link>
                    <Link to={'/market'}>
                        <div className={css.nftLink}>
                            <p>ROI NFTs</p>
                            <h3>Annual interest <br />Up to <span>45%</span></h3>
                            <img src={nfts} alt="" />
                        </div>
                    </Link>
                </div>
                <div className={css.middleRow}>
                    <Link to={'/show'}>
                        <div className={css.academyButton}>

                        </div>
                    </Link>
                </div>
                <div className={css.lastRow}>
                    <Link to={'https://drive.google.com/file/d/14L4xTpoYGAys7AtQF2bKCZZ3Drsz_flZ/view?usp=sharing'}>
                        <div className={css.pitchButton}>
                            <p>PITCHDECK</p>
                            <span>Learn more</span>
                        </div>
                    </Link>

                    <Link to={'/adventures'}>
                        <div className={css.tvButton}>
                            <p>UNI x CORN <br></br>TV SERIES</p>
                        </div>
                    </Link>

                    <Link>
                        <div className={css.marketButton}>
                            <p>MARKET PLACE</p>
                            <p>(soon)</p>
                        </div>
                    </Link>
                </div>
            </div>

            <div className={css.links}>
                <button onClick={() => {openLink('https://x.com/UnicademyShow')}}><img src={x} alt="" /></button>
                <button onClick={() => {openLink('https://ucorn.space')}}><img src={unicornIcon} alt="" /></button>
                <button onClick={() => {openLink('https://gempad.app/presale/0x5b3591B758D104104d79C05f7F600C6B54e055Ce?network=Base')}}><img src={cryptoIcon} alt="" /></button>
            </div>
      </div>
    )
}