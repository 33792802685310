import { useEffect } from 'react'
import css from './VideoPages.module.css'
import { Link } from 'react-router-dom'
import { launch } from '../api'
const tg = window.Telegram.WebApp

const tgFake = {
	initDataUnsafe: {
	  user: {
		id: 791601316
	  }
	}
  }

export default function Ecosystem() {

	useEffect(() => {
		document.body.classList = ''
  		document.body.classList.add('background-main');
	}, [])
    
    useEffect(() => {
      	launch({
        	id: tg.initDataUnsafe.user.id,
        	type: 'start'
    	})
        tg.ready()
    }, [])

    return (
        <div className={css.wrapper}>
            <video src="./ecosystem.mp4" controls></video>    

            <Link to={'/navigation'}>Launch App</Link>
        </div>
    )
}