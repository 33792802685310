import { useEffect } from 'react'
import css from './ShowPage.module.css'
import { Link } from 'react-router-dom'
import play from '../assets/play.png'
import back from '../assets/back.svg'
const tg = window.Telegram.WebApp

const tgFake = {
	initDataUnsafe: {
	  user: {
		id: 791601316
	  }
	}
  }

export default function ShowPage() {

	useEffect(() => {
		document.body.classList = ''
  		document.body.classList.add('background-show');
	}, [])
    
    useEffect(() => {
        tg.ready()
    }, [])

    return (
        <div className={css.wrapper}>
            <div className={css.header}>
				<Link to={'/navigation'}><img src={back} alt="" /></Link>
				<div className={css.right}>
					<h1>REALITY</h1>
                	<div className={css.doubleText}>
                	    <h1>SHOW</h1>
						<span>PILOT is <br /> COMING SOON</span>
                	</div>
				</div>
            </div>

			<div className={css.description}>
				<Link><img src={play} alt="" /></Link>

				<p>Watch startups compete for investment, hosted by global stars like Zendaya and Ryan Reynolds. Influence outcomes and invest in emerging businesses live!</p>
			</div>

      </div>
    )
}