import css from './ErrorPage.module.css'
import { useEffect, useRef, useState } from 'react';
import image from '../assets/unicornHint.png'

const tg = window.Telegram.WebApp

const tgFake = {
  initDataUnsafe: {
    user: {
      id: 791601316
    }
  }
}

export default function ErrorPage() {

	useEffect(() => {
		document.body.classList = ''
    	document.body.classList.add('background-main');

        tg.ready()
	}, [])



    return (
        <div className={css.gameCanvas}>
            <p>Oops, something went wrong! 😅<br></br>Can you please restart app?🔄</p>
            <img src={image} alt="" />
        </div>
    )
}