import css from './WelcomePage.module.css'
import { useEffect, useState } from 'react';
import {Link} from 'react-router-dom'
import Cool from '../assets/coolboy.png'
const tg = window.Telegram.WebApp

const tgFake = {
    initDataUnsafe: {
      user: {
        id: 791601316
      }
    }
  }

export default function WelcomePage() {
	document.body.classList = ''
  	document.body.classList.add('background-side');


    useEffect(() => {
        tg.ready()
        return 
    }, [])

    return (
        <div className={css.main}>
           <h2>Hey, Billie here...</h2>

			<div className={css.unicorn}>
				<img src={Cool} alt="" />
			</div>

			<div className={css.text}>
				<h1>Welcome!</h1>
				<p>Welcome to the enchanting world of Unicorn Space! Before you start your journey, let's walk you through the key features of the game to help you get the most out of your experience.</p>
			</div>
      	
			<Link to={'/guide'}>Let's Go</Link>
		</div>
    )
}