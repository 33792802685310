import css from './FinalPage.module.css'
import { useEffect, useState } from 'react';
import {Link} from 'react-router-dom'
import Unicorn from '../assets/unicornHint.png'
import Pizza from '../assets/pizzaHint.png'
import { verify } from '../api';
const tg = window.Telegram.WebApp

const tgFake = {
    initDataUnsafe: {
      user: {
        id: 791601316
      }
    }
}

export default function FinalPage() {
	document.body.classList = ''
  	document.body.classList.add('background-side');

    useEffect(() => {
        tg.ready()
		verify(tg.initDataUnsafe.user.id)
        return 
    }, [])

    return (
        <div className={css.main}>
           	<h2>Here’s the deal</h2>

			<div className={css.hints}>
				<div className={css.hint} id={css.first}>
					<div className={css.left}>
						<img src={Unicorn} alt="" />
					</div>
					<div className={css.right}>
						<h1>UniShop</h1>
						<p><span>Personalize</span> your unicorn with various <span>skins</span>, and <span>upgrades</span> to make it more productive and cool. Use <span>Mini UCC</span> or achieve <span>milestones</span> to unlock more Unithings!</p>
					</div>
				</div>
				<div className={css.hint} id={css.secondv2}>
					<div className={css.left}>
						<img src={Pizza} alt="" />
					</div>
					<div className={css.right}>
						<h1>Unicorn cycles</h1>
						<p>Your unicorn is giving you <span>$</span>, but you will have to <span>feed</span> it in return. To make sure your unicorn is <span>farming</span> lots of <span>Mini UCC</span>, pay attention on these 4 cycles: <span>9AM &gt; 3PM &gt; 9PM &gt; 3AM</span></p>
					</div>
				</div>
				<div className={css.hint} id={css.thirdv2}>
					<div className={css.right}>
						<h1>Ready to start?</h1>
						<p>Now that you know the basics, you're ready to start your journey at Unicorn Academy. Take care of your unicorn, explore new features, and have fun!<br></br><br></br>Tap <span>‘Let’s work!’</span> to begin your adventure!</p>
					</div>
				</div>
			</div>
      	
			<Link to={'/'}>Let's work!</Link>
		</div>
    )
}