import css from './GamePage.module.css'
import { useEffect, useRef, useState } from 'react';
// import unicorn from '../assets/unicorn1.png'
import shade from '../assets/shade.png'
import egg1 from '../assets/egg1.png'
import egg2 from '../assets/egg2.png'
import egg3 from '../assets/egg3.png'
import {Link, useSearchParams} from 'react-router-dom'
import { accomplishTask, collectCoins, feedUnicorn, getEasyUser, getUser, getUserGreetings, getUserGuides, greet, startFarm, tapEgg } from '../api';
import SweetImage from '../assets/sweet.svg'
import SourImage from '../assets/sour.svg'
import Home from '../assets/home.svg'
import Shop from '../assets/shop.svg'
import Tasks from '../assets/tasks.svg'
import Friends from '../assets/friends.svg'
import Other from '../assets/otherIcon.svg'
import healthImg from '../assets/health.svg'
import ms from 'ms';

import newborn from '../assets/newborn.png'
import babyborn from '../assets/babycorn.png'
import childcorn from '../assets/childcorn.png'
import teencorn from '../assets/childcorn.png'
import youngcorn from '../assets/youngcorn.png'
import adultcorn from '../assets/adultcorn.png'

const tg = window.Telegram.WebApp

const tgFake = {
  initDataUnsafe: {
    user: {
      id: 791601316
    }
  }
}

export default function UnicornStage() {

	useEffect(() => {
		document.body.classList = ''
    	document.body.classList.add('background-main');
	}, [])

    let [coins, setCoins] = useState(0)
    let [collectedCoins, setCollectedCoins] = useState(0)
    let [perSecRate, setRate] = useState(0)
    let [baseRate, setBRate] = useState(0)
    let [level, setLevel] = useState(0)
    let [unicornAge, setAge] = useState('Egg')
    let [health, setHealth] = useState(0)
    let [farmStarted, setFarmStart] = useState(0)
    let [farmEnd, setFarmEnd] = useState(0)
    let [farmStartedCheck, setFarmStartCheck] = useState(false)

    let [mainXP, setXP] = useState(0)
    let [needXP, setNeedXP] = useState(0)

    let [remainSour, setRemainSour] = useState(1)
    let [remainSweet, setRemainSweet] = useState(1)

    let [shake, setShake] = useState(false)

	let [eggStatus, setEggStatus] = useState(0)
  

    useEffect(() => {
      if(Object.keys(tg.initDataUnsafe).length !== 0) {
        getUser(tg.initDataUnsafe.user.id).then(res => {
			
		  	setEggStatus(res.hatching)
          	setCoins(res.coins)
          	setLevel(res.xp.level)
          	setAge(res.age)
          	setRate(res.rate/6/60/60)
          	setBRate(res.rate)
          	setHealth(res.health)
          	setRemainSour(res.sourFood)
          	setRemainSweet(res.sweetFood)
          	setXP(res.xp.xp)
          	setNeedXP(res.xp.xpToNextLevel)
          	setFarmStart(res.farmStarted)
          	setFarmEnd(res.farmEnd)

          	setFarmStartCheck(res.farmStartedCheck)
          
        	  let spentTime = (Date.now() - res.farmStarted)/1000
        	  let coins = spentTime * res.rate/6/60/60


        	  if(Date.now() < res.farmEnd) {
        	    setCollectedCoins(coins)
        	  } else {
        	    setCollectedCoins(baseRate)
        	  }
          
          tg.ready()
		})
      }
    }, [])

	let [unicorn, setUnicorn] = useState()

	useEffect(() => {
		if(unicornAge == 'Newborn') {
			setUnicorn(newborn)
		} else if(unicornAge == 'Babycorn') {
			setUnicorn(babyborn)
		} else if(unicornAge == 'Childcorn') {
			setUnicorn(childcorn)
		} else if(unicornAge == 'Teencorn') {
			setUnicorn(teencorn)
		} else if(unicornAge == 'Youngcorn') {
			setUnicorn(youngcorn)
		} else if(unicornAge == 'Adultcorn') {
			setUnicorn(adultcorn)
		}
	}, [unicornAge])
    
    useEffect(() => {
      let interval = setInterval(() => {
		if(farmStartedCheck == true) {
			if(Date.now() < farmEnd) {
				let newCollected = parseFloat(collectedCoins) + parseFloat(perSecRate)
		
				setCollectedCoins(newCollected)
			  } else {
				setCollectedCoins(baseRate)
			  }
		}
      }, 1000)

      return () => clearInterval(interval);
    }, [collectedCoins, perSecRate, farmStartedCheck]);


    async function handleCollect() {
      if(Date.now() - farmStarted > ms('6h')) {
        collectCoins(tg.initDataUnsafe.user.id).then(res => {
          setCoins(res.coins)
  
          setXP(res.xp)
          setLevel(res.level)
          setNeedXP(res.xpToNextLevel)
		  setFarmStartCheck(false)
		  setFarmEnd(null)
		  setFarmStart(null)
  
          setAge(res.age)
          setCollectedCoins(0)
        })
      } else {
        alert('You have to wait until farming period ends.')
      }
    }

    async function handleSweet() {
      feedUnicorn({
        telegramId: tg.initDataUnsafe.user.id,
        feed: 'sweetFood'
      }).then(res => {
        setHealth(res.health)
        setRemainSweet(res.quantity)
      })
    }

    async function handleSour() {
      feedUnicorn({
        telegramId: tg.initDataUnsafe.user.id,
        feed: 'sourFood'
      }).then(res => {
        setHealth(res.health)
        setRemainSour(res.quantity)
      })
    }

    async function shakeEgg(e) {
		if(!e.target.classList.contains(css.shake)) {
			setShake(true)

			setTimeout(() => {
			  setShake(false)
			}, 1000)
		}
    }

	async function handleStart() {
		startFarm(tg.initDataUnsafe.user.id).then((res) => {
			setFarmStart(res.farmStarted)
			setFarmEnd(res.farmEnd)
			setFarmStartCheck(true)
		})
    }

    return (
        <div className={css.gameCanvas}>
            <div className={css.topBlock}>
              <div className={css.coinsBlock}>
                <h2>M$UCC</h2>
                <h1>{parseFloat(coins).toFixed(4)}</h1>
              </div>

              <div className={css.levelBlock}>
                <div className={css.dayBlock}>
					<p>{level}</p>
				</div>
                <progress value={mainXP/needXP}></progress>
				<div className={css.dayBlock}>
                    <p>{level+1}</p>
				</div>
              </div>
              <span>{unicornAge}</span>
            </div>

			<div className={css.health}>
				<img src={healthImg} alt="" />
				<progress value={health/100}></progress>
			</div>

			<div className={css.unicornBlock}>
				<div className={css.backUni}></div>
				<img src={unicorn} id={css.unicorn} className={shake && eggStatus < 7 ? css.shake : ''} onClick={(e) => shakeEgg(e)} alt="" />
				<img src={shade} id={css.shade}  alt="" />
			</div>

			<div className={css.foodBlock}>
              <div className={css.buttons}>
                <div className={css.bBlock}>
                	<button disabled={remainSweet < 1} onClick={() => {handleSweet()}}><img src={SweetImage} /></button>
                    <p>x{remainSweet}</p>
                </div>
                <div className={css.bBlock}>
                	<button disabled={remainSour < 1} onClick={() => {handleSour()}}><img src={SourImage} /></button>
                    <p>x{remainSour}</p>
                </div>
              </div>
			</div>
            
			<div className={css.farmBlock}>
				{farmStartedCheck == true ? <button onClick={() => {
                handleCollect()
              }}>Farming {parseFloat(collectedCoins).toFixed(4)}</button> :               
			  <button onClick={() => {
                handleStart()
              }}>Start farm now</button>}
            </div>


            <div className={css.navBlock}>
                <Link to={'/'} id={css.sideLeft}><img src={Home} />Home</Link>
                <Link to={'/shop'}><img src={Shop} />UniShop</Link>
                <Link to={'/tasks'}><img src={Tasks} />Tasks</Link>
                <Link to={'/friends'}><img id={css.unifriends} src={Friends} />UniFrens</Link>
				<Link to={'/navigation'} id={css.sideRight}><img src={Other}/>Other</Link>
            </div>




      </div>
    )
}