import css from './TasksPage.module.css'
import { useEffect, useRef, useState } from 'react';
import {Link} from 'react-router-dom'
import Home from '../assets/home.svg'
import Shop from '../assets/shop.svg'
import Tasks from '../assets/tasks.svg'
import Friends from '../assets/friends.svg'
import backImage from '../assets/trophyBack.png'
import Other from '../assets/otherIcon.svg'
import { accomplishTask, claimTask, getTasks, getUserGreetings, greet, startTask } from '../api';
import completed from '../assets/completed.svg'
const tg = window.Telegram.WebApp

const tgFake = {
	initDataUnsafe: {
	  user: {
		id: 791601316
	  }
	}
  }

export default function TasksPage() {

	useEffect(() => {
		document.body.classList = ''
  		document.body.classList.add('background-side');
	}, [])

	let [tasks, setTasks] = useState([])
  
    useEffect(() => {
		getTasks(tg.initDataUnsafe.user.id).then(res => {
			setTasks(res.available.concat(res.ongoing))
		})
      tg.ready()
    }, [])


	function handleClaim(id) {
		claimTask({
			id,
			userId: tg.initDataUnsafe.user.id
		}).then(() => {
			window.location.reload()
		})
	}

	function handleStart(id) {
		startTask({
			tbId: id,
			userId: tg.initDataUnsafe.user.id
		}).then(() => {
			window.location.reload()
		})
	}

	function handleFollow(link, tbId) {
		if(link.startsWith('https://t.me')) {
			console.log('tg link')
			tg.openTelegramLink(link)
		} else {
			tg.openLink(link)
			console.log('other link')
		}

		accomplishTask({
			userId: tg.initDataUnsafe.user.id,
			tbId
		}).then(res => {
			window.location.reload()
		})
	}

    return (
        <div className={css.gameCanvas}>
            <img src={backImage} id={css.backImage} alt="" />
            <div className={css.textBlock}>
              <h1>Your Tasks</h1>
              <p><span>{tasks.length}</span> tasks available</p>
            </div>

            <div className={css.tasksBlock}>
				{tasks.map(task => {
					return (<div className={css.task}>
					<div className={css.description}>
					  <h2>{task.name} {task.status == 'ongoing' && `${task.progress}/${task.toComplete}`}</h2>
					  <p>+{task.reward} $MUDC</p>
					</div>
				 	{task.status == 'completed' && <img src={completed}></img> }
					{task.status == 'available' && <button onClick={() => {handleStart(task.id)}}>Start</button>}
					{((task.progress == task.toComplete || task.progress > task.toComplete) && task.status != 'completed') && <button onClick={() => {handleClaim(task.id)}}>Claim</button> }
					{(task.category == 'follow' && task.progress < task.toComplete) && <button onClick={() => {handleFollow(task.additional, task.tbId)}}>Follow</button>}
					{(task.category != 'follow' && task.progress < task.toComplete) && <h3>In progress</h3>}
				 </div>)
				})}
            </div>
            <div className={css.navBlock}>
                <Link to={'/'} id={css.sideLeft}><img src={Home} />Home</Link>
                <Link to={'/shop'}><img src={Shop} />UniShop</Link>
                <Link to={'/tasks'}><img src={Tasks} />Tasks</Link>
                <Link to={'/friends'}><img id={css.unifriends} src={Friends} />UniFrens</Link>
				<Link to={'/navigation'} id={css.sideRight}><img src={Other}/>Other</Link>
            </div>
      </div>
    )
}