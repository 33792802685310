import { useEffect } from 'react'
import css from './AdventuresPage.module.css'
import { Link } from 'react-router-dom'
import adv1 from '../assets/adv1.png'
import adv2 from '../assets/adv2.png'
import back from '../assets/back.svg'
const tg = window.Telegram.WebApp

const tgFake = {
	initDataUnsafe: {
	  user: {
		id: 791601316
	  }
	}
  }

export default function AdventuresPage() {

	useEffect(() => {
		document.body.classList = ''
  		document.body.classList.add('background-adventures');
	}, [])
    
    useEffect(() => {
        tg.ready()
    }, [])

    return (
        <div className={css.wrapper}>
            <div className={css.header}>
				<Link to={'/navigation'}><img src={back} alt="" /></Link>
				<div className={css.right}>
					<h1>Uni & Corn</h1>
	                <div className={css.doubleText}>
                    	<h1>ADVENTURES</h1>
                	</div>
				</div>
            </div>
            <div className={css.first}>
                <h1>FIRST SHORT is COMING SOON</h1>
            </div>

            <div className={css.main}>
                <p>2 brothers from Uniland,
                Uni and Corn came to our Earth together to build the future of tomorrow. Combining charisma and technology they save our Earth from Galaxy-scale dangers, going from planet to planet in a search of a highly appreciated resource - Investium.</p>
            </div>

            <img src={adv1} id={css.back1} alt="" />
            <img src={adv2} id={css.back2} alt="" />
      </div>
    )
}