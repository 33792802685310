import { createBrowserRouter } from "react-router-dom";
import GamePage from "./pages/GamePage";
import TasksPage from "./pages/TasksPage";
import FriendsPage from "./pages/FriendsPage";
import ShopPage from "./pages/ShopPage";
import WelcomePage from "./pages/WelcomePage";
import GuidePage from "./pages/GuidePage";
import FinalPage from "./pages/FinalPage";
import NavigationPage from "./pages/NavigationPage";
import AdventuresPage from "./pages/AdventuresPage";
import ShowPage from "./pages/ShowPage";
import MarketPlacePage from "./pages/MarketPlacePage";
import Ecosystem from "./pages/Ecosystem";
import RisingGuide from "./pages/RisingGuide";
import ErrorPage from "./pages/ErrorPage";

const router = createBrowserRouter([
  {
    path: "/navigation",
    element: <NavigationPage></NavigationPage>,
    errorElement: <ErrorPage></ErrorPage>
  },
  {
    path: '/adventures',
    element: <AdventuresPage></AdventuresPage>
  },
  {
    path: '/show',
    element: <ShowPage></ShowPage>
  },
  {
    path: '/market',
    element: <MarketPlacePage></MarketPlacePage>
  },
  {
    path: '/ecosystem',
    element: <Ecosystem></Ecosystem>
  },
  {
    path: '/guide',
    element: <RisingGuide></RisingGuide>,
    errorElement: <ErrorPage></ErrorPage>
  },
  {
    path: "/",
    element: <GamePage></GamePage>,
    errorElement: <ErrorPage></ErrorPage>
  },
  {
    path: '/tasks',
    element: <TasksPage></TasksPage>,
    errorElement: <ErrorPage></ErrorPage>
  }, 
  {
    path: "/friends",
    element: <FriendsPage></FriendsPage>,
    errorElement: <ErrorPage></ErrorPage>
  },
  {
    path: "/shop",
    element: <ShopPage></ShopPage>,
    errorElement: <ErrorPage></ErrorPage>
  },
  {
    path: '/welcome',
    element: <WelcomePage></WelcomePage>
  },
  {
    path: '/final',
    element: <FinalPage></FinalPage>
  },
  {
    path: '/error',
    element: <ErrorPage></ErrorPage>
  }
]);

export default router;